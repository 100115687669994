<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div slot-scope="row" slot="itemUserNameSlot">
        {{ row.userName ? row.userName: '暂无' }}
      </div>
      <div slot-scope="row" slot="action">
      </div>
    </a-table>
  </div>
</template>
<script>
import {penalizeTableColumns} from "@/views/cmsPage/shopManage/penalizeList/_data";

export default {
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: penalizeTableColumns
    }
  },
  methods: {
    /** 操作 */
    handleEvent(row, type) {
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
.icon-box {
  width: 100px;
  height: 100px;
}
.img-null {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}
.itemIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-red {
  color: red;
}
.mt-10 {
  margin-top: 10px;
}
</style>