var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("a-input", {
          staticStyle: { width: "200px" },
          attrs: {
            allowClear: "",
            size: "small",
            placeholder: "用户名/用户ID/昵称"
          },
          model: {
            value: _vm.params.keywordUser,
            callback: function($$v) {
              _vm.$set(_vm.params, "keywordUser", $$v)
            },
            expression: "params.keywordUser"
          }
        }),
        _c(
          "a-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.search }
          },
          [_vm._v("搜索")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("PenalizeTable", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: { changePage: _vm.handleChangePage, success: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }